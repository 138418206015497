import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
// hooks
import useAuth from '../hooks/useAuth';
// components
import { MotionContainer, varBounce } from '../components/animate';
import Page from '../components/Page';
// assets
import { ForbiddenIllustration } from '../assets';



// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  hasContent: PropTypes.bool,
  role: PropTypes.string, // Example ['admin', 'leader']
  children: PropTypes.node.isRequired,
};

export default function RoleBasedGuard({ hasContent, role, children }) {
  // Logic here to get current user role
  const { user } = useAuth();

  // const currentRole = 'user';
  const currentRoles = user?.roles;

  if (typeof currentRoles !== 'undefined' && !currentRoles.includes(role)) {
    return hasContent ? (
      <Page title="Permission Denied">
        <RootStyle>
          <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
            <m.div variants={varBounce().in}>
              <ForbiddenIllustration sx={{ height: 260, my: { xs: 10, sm: 5 } }} />
            </m.div>

            <m.div variants={varBounce().in}>
              <Typography variant="h3" paragraph>
                Permission Denied
              </Typography>
            </m.div>

            <m.div variants={varBounce().in}>
              <Typography sx={{ color: 'text.secondary' }}>You do not have permission to access this page</Typography>
            </m.div>
          </Container>
        </RootStyle>
      </Page>
    ) : null;
  }

  return <>{children}</>;
}
