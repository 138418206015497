import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import RoleBasedGuard from '../guards/RoleBasedGuard';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/app')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { pathname } = useLocation();
  return useRoutes([
    {
      path: '/',
      element: <Navigate to="/app" replace />,
    },

    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'reset-password',
          element: (
            <GuestGuard>
              <ResetPassword />
            </GuestGuard>
          ),
        },
      ],
    },

    // Dashboard Routes
    {
      path: 'app',
      element: pathname === '/app' ? (<Navigate to="/app/dashboard" replace />) : (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard',
          element: (
            <RoleBasedGuard role={'dashboard'} hasContent>
              <Dashboard />
            </RoleBasedGuard>
          )
        },
        {
          path: 'changepassword',
          element: (
            <ChangePassword />
          ),
        },
        {
          path: 'customers',
          element: (
            <RoleBasedGuard role={'customers'} hasContent>
              <AllCustomers />
            </RoleBasedGuard>
          )
        },
        {
          path: 'sales',
          element: (
            <RoleBasedGuard role={'sales'} hasContent>
              <Sales />
            </RoleBasedGuard>
          )
        },
        {
          path: 'orders',
          children: [
            {
              path: 'all-orders',
              element: (
                <RoleBasedGuard role={'orders'} hasContent>
                  <AllOrders />
                </RoleBasedGuard>
              )
            },
            {
              path: 'view-order',
              element: (
                <RoleBasedGuard role={'orders'} hasContent>
                  <ViewOrder />
                </RoleBasedGuard>
              )
            }
          ],
        },
        {
          path: 'complains',
          children: [
            {
              path: 'all-complains',
              element: (
                <RoleBasedGuard role={'complains'} hasContent>
                  <Complains />
                </RoleBasedGuard>
              )
            },
            {
              path: 'view-complain',
              element: (
                <RoleBasedGuard role={'complains'} hasContent>
                  <ViewComplain />
                </RoleBasedGuard>
              )
            },
          ],

        },
        {
          path: 'drivers',
          children: [
            {
              path: 'all-drivers',
              element: (
                <RoleBasedGuard role={'drivers'} hasContent>
                  <AllDrivers />
                </RoleBasedGuard>
              )
            },
            {
              path: 'create-driver',
              element: (
                <RoleBasedGuard role={'drivers'} hasContent>
                  <CreateDriver />
                </RoleBasedGuard>
              )
            },
          ],
        },
        {
          path: 'admins',
          children: [
            {
              path: 'all-admins',
              element: (
                <RoleBasedGuard role={'admins'} hasContent>
                  <AllAdmins />
                </RoleBasedGuard>
              )
            },
            {
              path: 'create-admin',
              element: (
                <RoleBasedGuard role={'admins'} hasContent>
                  <CreateAdmin />
                </RoleBasedGuard>
              )
            },
            {
              path: 'edit-admin',
              element: (
                <RoleBasedGuard role={'admins'} hasContent>
                  <EditAdmin />
                </RoleBasedGuard>
              )
            },
          ],
        },
        {
          path: 'products',
          children: [
            {
              path: 'all-products',
              element: (
                <RoleBasedGuard role={'products'} hasContent>
                  <AllProducts />
                </RoleBasedGuard>
              )
            },
            {
              path: 'create-product',
              element: (
                <RoleBasedGuard role={'products'} hasContent>
                  <CreateProduct />
                </RoleBasedGuard>
              )
            },
            {
              path: 'edit-product',
              element: (
                <RoleBasedGuard role={'products'} hasContent>
                  <EditProduct />
                </RoleBasedGuard>
              )
            },
          ],
        },
        {
          path: 'promocodes',
          children: [
            {
              path: 'all-promocodes',
              element: (
                <RoleBasedGuard role={'promocodes'} hasContent>
                  <AllPromocodes />
                </RoleBasedGuard>
              )
            },
            {
              path: 'create-promocode',
              element: (
                <RoleBasedGuard role={'promocodes'} hasContent>
                  <CreatePromocode />
                </RoleBasedGuard>
              )
            },
          ],
        },
        {
          path: 'banners',
          children: [
            {
              path: 'all-banners',
              element: (
                <RoleBasedGuard role={'banners'} hasContent>
                  <AllBanners />
                </RoleBasedGuard>
              )
            },
            {
              path: 'create-banner',
              element: (
                <RoleBasedGuard role={'banners'} hasContent>
                  <CreateBanner />
                </RoleBasedGuard>
              )
            },
          ],
        },
        {
          path: 'settings',
          element: (
            <RoleBasedGuard role={'settings'} hasContent>
              <Settings />
            </RoleBasedGuard>
          )
        },
      ],
    },

    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const ChangePassword = Loadable(lazy(() => import('../pages/ChangePassword')));
// Dashboard
const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')));
const Sales = Loadable(lazy(() => import('../pages/Sales')));
// Admins
const AllAdmins = Loadable(lazy(() => import('../pages/admins/AllAdmins')));
const CreateAdmin = Loadable(lazy(() => import('../pages/admins/CreateAdmin')));
const EditAdmin = Loadable(lazy(() => import('../pages/admins/EditAdmin')));
// Drivers
const AllDrivers = Loadable(lazy(() => import('../pages/drivers/AllDrivers')));
const CreateDriver = Loadable(lazy(() => import('../pages/drivers/CreateDriver')));
// Customers
const AllCustomers = Loadable(lazy(() => import('../pages/AllCustomers')));
// Products
const AllProducts = Loadable(lazy(() => import('../pages/products/AllProducts')));
const CreateProduct = Loadable(lazy(() => import('../pages/products/CreateProduct')));
const EditProduct = Loadable(lazy(() => import('../pages/products/EditProduct')));
// Orders
const AllOrders = Loadable(lazy(() => import('../pages/orders/AllOrders')));
const ViewOrder = Loadable(lazy(() => import('../pages/orders/ViewOrder')));
// Settings
const Settings = Loadable(lazy(() => import('../pages/Settings')));
// Promocodes
const AllPromocodes = Loadable(lazy(() => import('../pages/promocodes/AllPromocodes')));
const CreatePromocode = Loadable(lazy(() => import('../pages/promocodes/CreatePromocode')));
// Complains
const Complains = Loadable(lazy(() => import('../pages/complains/AllComplains')));
const ViewComplain = Loadable(lazy(() => import('../pages/complains/ViewComplain')));
// Banners
const AllBanners = Loadable(lazy(() => import('../pages/banners/AllBanners')));
const CreateBanner = Loadable(lazy(() => import('../pages/banners/CreateBanner')));