import { memo } from 'react';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

function VegIcon({ ...other }) {

    return (
        <Box {...other}>
            <svg width="18" height="18" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.6" y="0.6" width="10.8" height="10.8" stroke="#00AE54" stroke-width="1.2" />
                <circle cx="5.99961" cy="5.99998" r="2.4" fill="#00AE54" />
            </svg>
        </Box>
    );
}

export default memo(VegIcon);
