// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
};

const navConfig = [

  // Overview
  // ----------------------------------------------------------------------
  {
    subheader: 'overview',
    items: [
      {
        title: 'dashboard',
        path: '/app/dashboard',
        icon: ICONS.dashboard,
        role: "dashboard",
      },
    ],
  },


  // Sales
  // ----------------------------------------------------------------------
  {
    subheader: 'sales',
    items: [
      {
        title: 'sales',
        path: '/app/sales',
        icon: ICONS.analytics,
        role: "sales",
      },
    ],
  },


  // Users
  // ----------------------------------------------------------------------
  {
    subheader: 'users',
    items: [
      {
        title: 'customers',
        path: '/app/customers',
        icon: ICONS.user,
        role: "customers",
      },
      {
        title: 'drivers',
        path: '/app/drivers',
        icon: ICONS.user,
        role: "drivers",
        children: [
          { title: 'All Drivers', path: '/app/drivers/all-drivers' },
          { title: 'Create Driver', path: '/app/drivers/create-driver' },
        ],
      },
      {
        title: 'admins',
        path: '/app/admins',
        icon: ICONS.user,
        role: "admins",
        children: [
          { title: 'All Admins', path: '/app/admins/all-admins' },
          { title: 'Create Admin', path: '/app/admins/create-admin' },
        ],
      },
     ],
  },

  // Management
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      {
        title: 'orders',
        path: '/app/orders/all-orders',
        role: "orders",
        icon: ICONS.invoice,
      },
      {
        title: 'products',
        path: '/app/products',
        role: "products",
        icon: ICONS.ecommerce,
        children: [
          { title: 'All Products', path: '/app/products/all-products' },
          { title: 'Create Product', path: '/app/products/create-product' },
        ],
      },
      {
        title: 'promocodes',
        path: '/app/promocodes',
        role: "promocodes",
        icon: ICONS.menuItem,
        children: [
          { title: 'All Promo-codes', path: '/app/promocodes/all-promocodes' },
          { title: 'Create Promo-code', path: '/app/promocodes/create-promocode' },
        ],
      },
      {
        title: 'banners',
        path: '/app/banners',
        icon: ICONS.menuItem,
        role: "banners",
        children: [
          { title: 'All Banners', path: '/app/banners/all-banners' },
          { title: 'Create Banner', path: '/app/banners/create-banner' },
        ],
      },
     ],
  },

  // Support
  // ----------------------------------------------------------------------
  {
    subheader: 'support',
    items: [
      {
        title: 'complains',
        role: "complains",
        path: '/app/complains/all-complains',
        icon: ICONS.chat,
      },
    ],
  },

  {
    subheader: 'settings',
    items: [
      {
        title: 'settings',
        path: '/app/settings',
        icon: ICONS.menuItem,
        role: "settings",
      },
    ],
  },
];

export default navConfig;
